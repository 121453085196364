import * as React from 'react';

import { Layout } from 'components/Layout/Layout';
import { PopupMessage } from 'components/PopupMessage/PopupMessage';
import { TutorialInfomationPopUpMessage } from 'components/TutorialPopUpMessage/TutorialInfomationPopUpMessage';

import { BookingWidgetPage } from './BookingWidgetPage';

type Props = {
  children: React.ReactNode;
};

export const BookingWidgetLayout = ({ children }: Props) => {
  return (
    <Layout>
      <PopupMessage />
      <TutorialInfomationPopUpMessage />
      <BookingWidgetPage>{children}</BookingWidgetPage>
    </Layout>
  );
};
