import * as React from 'react';

import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { ProductListViewProps } from 'lib/themes/themes';
import { getCustomComponent } from 'components/Themes';

export const ProductListView = (props: ProductListViewProps) => {
  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);

  const C = getCustomComponent(bookingWidgetTheme, 'ProductListView');
  return <C {...props} />;
};
