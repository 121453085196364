import * as React from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import clsx from 'clsx';

import { getExtranetUrl } from 'lib/util/getExtranetUrl';

import styles from 'components/PopupMessage/PopupMessage.module.css';
import { ReduxState } from 'ducks';

export const TutorialInfomationPopUpMessage = (): React.ReactElement | null => {
  const { t } = useTranslation();
  const router = useRouter();
  const [show, setShow] = React.useState(false);

  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const isTutorial = settings?.tutorial_stage === 'TEST_RESERVATION';

  const helpPageUrl = `${getExtranetUrl()}/helps/intro/test-booking`;

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 2 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const defaultPopupPaths: string[] = [
    '/top',
    '/[apiKey]',
    '/top/products/[id]',
    '/[apiKey]/products/[id]',
    '/products/[id]',
  ];

  if (!show || !isTutorial || !defaultPopupPaths.includes(router.pathname)) {
    return null;
  }

  return (
    <div className={styles['snackbar-container']}>
      <div className={clsx(styles['snackbar'])}>
        <a
          onClick={() => {
            setShow(false);
          }}
          className={clsx(styles['snackbar-close'])}
        />
        <Box
          display="flex"
          justifyContent="center"
          ml={1}
          mt={2}
          mb={2}
          className={styles['snackbar-title']}
        >
          {t('STEP2 - Test Booking')}
        </Box>
        <Box display="flex" justifyContent="center" ml={1} mb={2}>
          {t('Make a test booking.')}
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="center" ml={1} mb={2}>
          <p>{t('Select one product and make a test booking.')}</p>
          <p>
            {t(
              'As you browse through the Nutmeg booking website, see the product pages and checkout flow and make a new booking.'
            )}
          </p>
        </Box>
        <Box mb={2} display="flex" justifyContent="center">
          <a target="_blank" rel="noopener noreferrer" href={helpPageUrl}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setShow(false);
              }}
            >
              {t('About test booking')}
            </Button>
          </a>
        </Box>
      </div>
    </div>
  );
};
