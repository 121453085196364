import { ProductSummaryShape } from 'lib/util/productSummaryShape';
import { GetSettingsResponse } from 'models/settings';

export const filterPartnershipProductSummaryShapes = (
  productSummaryShapes: ProductSummaryShape[],
  settings: GetSettingsResponse
) => {
  const enablePartnerProductIds: string[] = [];
  for (const supplierProduct of settings?.partnership_settings?.supplier_products || []) {
    for (const product of supplierProduct?.products || []) {
      if (product.status === 'SALE' && product.product_id) {
        enablePartnerProductIds.push(product.product_id);
      }
    }
  }
  return productSummaryShapes.filter((productSummary) => {
    return enablePartnerProductIds.includes(productSummary.id);
  });
};
