interface SupplierStructuredReviewData {
  '@context': 'https://schema.org/';
  '@type': 'LocalBusiness';
  image: string;
  name: string;
  aggregateRating: {
    '@type': 'AggregateRating';
    ratingValue: number;
    ratingCount: number;
    bestRating: '5';
  };
}

export const getSupplierStructuredReviewData = (
  image: string,
  supplierName: string,
  reviewRating: number,
  reviewCount: number
): SupplierStructuredReviewData => ({
  '@context': 'https://schema.org/',
  '@type': 'LocalBusiness',
  image: image,
  name: supplierName,
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: reviewRating,
    ratingCount: reviewCount,
    bestRating: '5',
  },
});
