import * as React from 'react';
import { useRouter } from 'next/router';

import { Footer } from 'components/Footer/Footer';
import { BookingWidgetHeader } from 'components/BookingWidgetHeader/BookingWidgetHeader';
import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { isEssentialPagePath } from 'lib/util/isEssentialPagePath';
import { LayoutContext } from 'contexts/LayoutContext';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import dynamic from 'next/dynamic';
import { FalconUIContextProvider } from 'components/FalconUIContextProvider/FalconUIContextProvider';
import '@nutmeglabs/falcon-ui/dist/esm/index.css';
import { GroupBookingHeader } from 'components/Themes/Default/GroupBookingHeader/GroupBookingHeader';
import { useHeaderDescriptionTagType } from 'hooks/useHeaderDescriptionTagType';

const CustomHeader = dynamic(() => import('@nutmeglabs/falcon-ui').then((mod) => mod.Header));
const CustomFooter = dynamic(() => import('@nutmeglabs/falcon-ui').then((mod) => mod.Footer));

type Props = {
  children: React.ReactNode;
};

export const BookingWidgetPage = ({ children }: Props) => {
  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);
  const { showHeaderFooter } = React.useContext(LayoutContext);

  const router = useRouter();
  const shouldUseCustomTopPage = useSelector(
    (state: ReduxState) => state.server.settings.all?.is_custom_top_page_enabled
  );
  const groupBookingTemplate = useSelector(
    (state: ReduxState) => state.server.groupBookingTemplate.groupBookingTemplate
  );
  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const operatingHoursRules = settings?.operating_hours_rules;
  let operatingHoursDescription = '';
  if (operatingHoursRules && operatingHoursRules.length > 0) {
    operatingHoursDescription = `${operatingHoursRules[0].start_time_local} - ${operatingHoursRules[0].end_time_local}`;
  }
  const topPageData = useSelector((state: ReduxState) => state.server.customTopPage.page);
  const shouldUseCustomHeaderFooter = shouldUseCustomTopPage && topPageData;
  const shouldUseGroupBookingHeader = Boolean(groupBookingTemplate);
  const headerDescriptionTag = useHeaderDescriptionTagType();

  const shouldShowHeader =
    (showHeaderFooter &&
      (bookingWidgetTheme !== 'VIBRANT' ||
        router.asPath?.includes('checkout') ||
        isEssentialPagePath(router.asPath ?? '') ||
        router.asPath?.includes('qrreader'))) ||
    router.asPath?.includes('imagedownload');

  return (
    <div className="base-wrap">
      <FalconUIContextProvider>
        {shouldShowHeader &&
          (shouldUseGroupBookingHeader ? (
            <GroupBookingHeader />
          ) : shouldUseCustomHeaderFooter ? (
            <CustomHeader
              data={{
                ...topPageData,
                headerStyle: 'OPAQUE',
              }}
              pageDescriptionTag={headerDescriptionTag === 'h1' ? 'h1' : 'div'}
            />
          ) : (
            <BookingWidgetHeader />
          ))}
        <main className="base-main">{children}</main>
        {showHeaderFooter &&
          (shouldUseCustomHeaderFooter ? (
            <CustomFooter
              data={topPageData}
              orgInfo={{
                phone: settings?.supplier_phone ?? '',
                email: settings?.supplier_email ?? '',
                address: settings?.supplier_address ?? '',
                operatingHoursDescription,
                hideFooterJapaneseTermsLink:
                  settings?.booking_widget_design_params?.hide_footer_japanese_terms_link ?? false,
                snsIds: settings?.sns_ids ?? [],
                showTravelAgencyRegistrationForm:
                  settings?.reservation_payment_gateway_settings?.payment_gateway === 'GMO',
              }}
            />
          ) : (
            <Footer />
          ))}
      </FalconUIContextProvider>
    </div>
  );
};
