import Skeleton from '@mui/material/Skeleton';
import * as React from 'react';
import Link from 'next/link';
import { useSelector } from 'react-redux';

import { toHeaderSettingsShape } from 'components/Header/util';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { ReduxState } from 'ducks';

import styles from '../BookingWidgetHeader/BookingWidgetHeader.module.css';
import { useQueryString } from 'hooks/useQueryString';

export const GroupBookingHeader = () => {
  const { apiKey } = React.useContext(ApiKeyContext);
  const groupBookingTemplate = useSelector(
    (state: ReduxState) => state.server.groupBookingTemplate.groupBookingTemplate
  );

  const apiSettings = useSelector((state: ReduxState) => state.server.settings.all);

  const settings = toHeaderSettingsShape(apiSettings);

  const configQueryString = useQueryString();

  const logoUrl = groupBookingTemplate?.logo_url ?? settings.logoURL;

  let productListPath = `/${apiKey}/groups/${groupBookingTemplate?.id}`;
  if (configQueryString) {
    productListPath = `${productListPath}?${configQueryString}`;
  }

  return (
    <>
      <header className={styles['c-header']}>
        <div className={styles['c-header__inner']}>
          <Link href={productListPath} className={styles['c-header__ttl']}>
            <div className={styles['c-header__ttl__logo']}>
              {logoUrl ? (
                <picture>
                  <img src={logoUrl} alt="Logo" />
                </picture>
              ) : (
                <Skeleton variant="rectangular" width={32} height={32} />
              )}
            </div>
            <div className={styles['c-header__ttl__name']}>{groupBookingTemplate?.group_name}</div>
          </Link>
        </div>
      </header>
    </>
  );
};
