import config from 'config';

export const getExtranetUrl = (): string => {
  let url = '';

  switch (config.envName) {
    case 'development':
      url = `https://app.dev.ntmg.com`;
      break;
    case 'staging':
      url = `https://app.stg.ntmg.com`;
      break;
    case 'production':
      url = `https://app.ntmg.com`;
      break;
  }

  return url;
};
