import * as React from 'react';

import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { getCustomComponent } from 'components/Themes';

export const Footer = () => {
  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);

  const CustomComponent = getCustomComponent(bookingWidgetTheme, 'BookingWidgetFooter');

  return <CustomComponent />;
};
